import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField, DropdownSelect, TextArea, Checkbox } from '@tableau/tableau-ui';
import Templates from '../../../utils/templates';
import ExportBtn from '../../Extension/ExportBtn/ExportBtn';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  gridItem: {
    marginTop: 10
  }
}));

const useStylesIcon = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  gridItem: {
    marginTop: 30
  }
}));

function ConfigureTab(props) {
  const classes = useStyles();
  const classesIcon = useStylesIcon();

  const labelProps = {
    label: 'Nombre botón',
    onChange: e => {
      console.log('[ConfigureTab.js] Updating Button Label', e.target.value)
      props.updateLabel(e.target.value)
    },
    onClear: () => {
      props.updateLabel('Export All');
    },
    placeholder: 'escribe el nombre del botón',
    style: { width: 400 },
    value: props.label || '',
  };

  const filenameProps = {
    label: 'Nombre fichero',
    onChange: e => props.updateFilename(JSON.stringify(e.target.value)),
    onClear: () => {
      props.updateFilename('ExportAll');
    },
    placeholder: 'escribe el nombre del fichero',
    style: { width: 400 },
    value: props.filename || '',
  };

  const styleDropDown = {
    marginLeft: 10
  }

  const stylesButtonTextArea = {
    label: "CSS Botón",
    value: props.cssStyle ? JSON.parse(props.cssStyle) : Templates.defaultCSSButton().trim(),
    onChange: e => {
      // alert(e.target.value)
      props.updateCustomCssButton(e.target.value);
    },
  }

  const changeFormat = (e) => {
    props.updateFormat(e.target.value)
  }

  const urlProps = {
    label: 'Url',
    onChange: e => props.updateUrl(e.target.value),
    onClear: () => {
      props.updateUrl('');
    },
    placeholder: 'url donde quieres enviar los datos',
    style: { width: 400 },
    value: props.url || '',
  };

  const IconProps = {
    label: <a href="https://mui.com/components/material-icons/" target="_blank">Icon</a>,
    onChange: e => props.updateIcon(e.target.value),
    onClear: () => { props.updateIcon('') },
    placeholder: 'icono',
    style: { width: 100 },
    value: props.icon || '',
  };

  return (
    <div className={classes.root}>
      {/* {alert("propsformat ---" + props.cssStyle)} */}
      <Grid container>
        <Grid item xs={12}>
          <TextField {...labelProps} />
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <DropdownSelect kind='line' label="Tipo Extracto:" value={props.format} style={styleDropDown} onChange={(e) => changeFormat(e)}>
            <option value="excel">Excel</option>
            <option value="url">Vía url</option>
          </DropdownSelect>
        </Grid>
        {
          props.format === "excel" ?
            <Grid item xs={12} className={classes.gridItem}>
              <TextField {...filenameProps} />
            </Grid> : null
        }

        {
          props.format === "url" ?
            <Grid item xs={12} className={classes.gridItem}>
              <TextField {...urlProps} />
            </Grid> : null
        }

        <Grid item xs={3} className={classes.gridItem}>
          <React.Fragment>
            <DropdownSelect kind='line' label="Estilos botón:" value={props.style} onChange={e => props.updateButtonStyle(JSON.stringify(e.target.value))} style={styleDropDown}>
              <option>outline</option>
              <option>primary</option>
              <option>destructive</option>
              <option>grutinet</option>
              <option>personalizar</option>
            </DropdownSelect>
          </React.Fragment>

        </Grid>

        <Grid item xs={12}>
        <Grid item xs={3} className={classes.gridItem}>
          <React.Fragment>
            <DropdownSelect kind='line' label="Ignorar Selector dashboard:" value={props?.ignoreSelection} onChange={(e) => props?.updateIgnoreSelection(JSON.stringify(e.target.value))} style={styleDropDown}>
              <option value={true}>Sí</option>
              <option value={false}>No</option>
            </DropdownSelect>
          </React.Fragment>

        </Grid>
        </Grid>

        

        <Grid item xs={3} className={classes.gridItem}>
          <TextField {...IconProps} />
        </Grid>

        <Grid item xs={12} className={classesIcon.gridItem}>
          <InputLabel style={{ marginBottom: 5 }}>Prevista Botón</InputLabel>
          <ExportBtn
            icon={props.icon}
            cssStyle={props.cssStyle}
            label={props.label}
            style={props.style}
            disabled={props.disabled} >
          </ExportBtn>
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          {
            props.style === "personalizar" ?
              <TextArea {...stylesButtonTextArea} rows="10" cols="80" />
              : null
          }
        </Grid>

      </Grid>
    </div>
  )

}

export default ConfigureTab;
