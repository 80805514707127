import React from 'react';
import Sheet from './Sheet/Sheet'

function SelectParameters(props) {
  if (props && props.parameters) {
    return props.parameters.map((param, index) => {
        return (
        <Sheet
            param={param.name}
            value={param.value}
            selected={param.selected}
            updateParameter={()=>props.updateParameter(index,param.value)}
          />
      )
    });
  } else {
    return (
      <div>No hay parámetros</div>
    )
  }

}

export default SelectParameters;
