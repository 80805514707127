import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Extension from '../components/Extension/Extension';
import Configure from '../components/Configure/Configure';
import DesktopExport from '../components/DesktopExport/DesktopExport';
import './Main.css';

class Main extends Component {

  constructor(props) {
    console.log('[Main.js] new constructor');
    super(props);
    this.state = {
      meta: undefined,
      parameters: [],
      metaVersion: 2,
      button: {
        label: 'Export All',
        style: 'outline',
        cssStyle: '',
        disabled: true
      },
      icon: undefined,
      url: '',
      format: 'excel',
      isLoading: false,
      filename: 'ExportAll',
      ignoreSelection: true,
      settingsChanged: false,
    };
    console.log('[Main.js] state initialised', this.state);
  }

  componentWillMount() {
    console.log('[Main.js] componentWillMount');
  }

  componentDidMount() {
    console.log('[Main.js] componentDidMount');
  }

  metaVersionChangedHandler = (version) => {
    console.log(`[Main.js] Updating meta version to ${version}`);
    this.setState({ metaVersion: version });
  }

  labelChangedHandler = (labelTxt) => {
    console.log(`[Main.js] Updating label text to ${labelTxt}`);
    const button = { ...this.state.button, label: labelTxt };
    this.setState({ button });
  }

  filenameChangedHandler = (filename) => {
    console.log(`[Main.js] Updating filename to ${JSON.parse(filename)}`);
    this.setState({ filename: JSON.parse(filename) });
  }

  ignoreSelectionChangedHandler = (value) => {
    console.log(`[Main.js] Updating ignoreSelectionChangedHandler to ${value}`);
    value = (JSON.parse(value) === "true" || JSON.parse(value) === true) ? true : false
    this.setState({ ignoreSelection: value });
  }

  buttonStateChangedHandler = (disabled) => {
    console.log(`[Main.js] Changing button state to ${disabled}`);
    const button = { ...this.state.button, disabled };
    this.setState({ button });
  }

  buttonCSStateChangedHandler = (cssStyle) => {
    // alert("buttonCSStateChangedHandler" + cssStyle)

    const button = { ...this.state.button, cssStyle: JSON.stringify(cssStyle) };
    this.setState({ button });


  }

  stateChangeUrl = (url) => this.setState({ url: url })


  buttonStyleChangedHandler = (style) => {
    // console.log(`[Main.js] Changing button style to ${style}`);
    // alert(style)
    const button = {
      ...this.state.button,
      style: JSON.parse(style),
      cssStyle: style !== "personalizar" ? '' : this.state.button.cssStyle
    };
    this.setState({ button });
  }

  metaChangedHandler = (newSettings) => {
    console.log('[Main.js] Meta changed', newSettings);
    this.setState({ meta: newSettings });
  }

  parameterChangedHandler = (parameter) => {
    this.setState({ parameters: parameter });
  }

  formatChangedHandler = (format) => {
    this.setState({ format: format });
  }

  iconChangedHandler = (icon) => {
    // alert("iconChangedHandler " + icon)
    this.setState({ icon: icon });
  }

  settingsChangedHandler = (state) => {
    console.log('[Main.js] Settings changed', state);
    this.setState({ settingsChanged: state });
  }

  resetSettingsHandler = () => {
    console.log('[Main.js] Reset Settings');
    this.setState({
      meta: undefined,
      parameters: [],
      metaVersion: 2,
      button: {
        label: 'Export All',
        style: 'outline',
        disabled: true,
        cssStyle: '',
      },
      icon: undefined,
      url: '',
      format: 'excel',
      isLoading: false,
      filename: 'ExportAll',
      settingsChanged: false,
    });
  }

  renderExtension = () => {
    return (
      <Extension
        label={this.state.button.label}
        meta={this.state.meta}
        parameters={this.state.parameters}
        format={this.state.format}
        url={this.state.url}
        icon={this.state.icon}
        updateIcon={this.iconChangedHandler}
        updateUrl={this.stateChangeUrl}
        updateFormat={this.formatChangedHandler}
        metaVersion={this.state.metaVersion}
        style={this.state.button.style}
        cssStyle={this.state.button.cssStyle}
        filename={this.state.filename}
        disabled={this.state.button.disabled}
        ignoreSelection={this.state.ignoreSelection}
        updateMetaVersion={this.metaVersionChangedHandler}
        updateMeta={this.metaChangedHandler}
        updateParameter={this.parameterChangedHandler}
        disableButton={this.buttonStateChangedHandler}
        updateLabel={this.labelChangedHandler}
        updateButtonStyle={this.buttonStyleChangedHandler}
        updateFilename={this.filenameChangedHandler}
        updateIgnoreSelection={this.ignoreSelectionChangedHandler}
        updateCustomCssButton={this.buttonCSStateChangedHandler} />
    )
  }

  renderConfigure = () => {
    return (
      <Configure
        label={this.state.button.label}
        meta={this.state.meta}
        parameters={this.state.parameters}
        format={this.state.format}
        ignoreSelection={this.state.ignoreSelection}
        url={this.state.url}
        icon={this.state.icon}
        updateIcon={this.iconChangedHandler}
        updateUrl={this.stateChangeUrl}
        updateFormat={this.formatChangedHandler}
        style={this.state.button.style}
        filename={this.state.filename}
        cssStyle={this.state.button.cssStyle}
        enableSave={this.state.settingsChanged}
        disabled={this.state.button.disabled}
        updateMeta={this.metaChangedHandler}
        updateParameter={this.parameterChangedHandler}
        disableButton={this.buttonStateChangedHandler}
        updateIgnoreSelection={this.ignoreSelectionChangedHandler}
        updateLabel={this.labelChangedHandler}
        changeSettings={this.settingsChangedHandler}
        updateButtonStyle={this.buttonStyleChangedHandler}
        updateFilename={this.filenameChangedHandler}
        resetSettings={this.resetSettingsHandler}
        updateCustomCssButton={this.buttonCSStateChangedHandler} />
    )
  }

  renderDesktopExport = () => {
    return (
      <DesktopExport />
    )
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/configure/a">
            {/* {JSON.stringify(this.state.button.cssStyle)} */}
            <Configure
              label={this.state.button.label}
              meta={this.state.meta}
              parameters={this.state.parameters}
              format={this.state.format}
              url={this.state.url}
              ignoreSelection={this.state.ignoreSelection}
              icon={this.state.icon}
              updateIcon={this.iconChangedHandler}
              updateUrl={this.stateChangeUrl}
              updateFormat={this.formatChangedHandler}
              style={this.state.button.style}
              cssStyle={this.state.button.cssStyle}
              filename={this.state.filename}
              updateIgnoreSelection={this.ignoreSelectionChangedHandler}
              disabled={this.state.button.disabled}
              enableSave={this.state.settingsChanged}
              updateMeta={this.metaChangedHandler}
              updateParameter={this.parameterChangedHandler}
              disableButton={this.buttonStateChangedHandler}
              updateLabel={this.labelChangedHandler}
              changeSettings={this.settingsChangedHandler}
              updateButtonStyle={this.buttonStyleChangedHandler}
              updateFilename={this.filenameChangedHandler}
              updateCustomCssButton={this.buttonCSStateChangedHandler}
              resetSettings={this.resetSettingsHandler} />
          </Route>
          <Route path="/desktopexport">
            <DesktopExport />
          </Route>
          <Route path="/">
            <Extension
              label={this.state.button.label}
              meta={this.state.meta}
              parameters={this.state.parameters}
              format={this.state.format}
              url={this.state.url}
              ignoreSelection={this.state.ignoreSelection}
              icon={this.state.icon}
              updateIcon={this.iconChangedHandler}
              updateUrl={this.stateChangeUrl}
              updateFormat={this.formatChangedHandler}
              metaVersion={this.state.metaVersion}
              style={this.state.button.style}
              cssStyle={this.state.button.cssStyle}
              filename={this.state.filename}
              disabled={this.state.button.disabled}
              updateIgnoreSelection={this.ignoreSelectionChangedHandler}
              updateMetaVersion={this.metaVersionChangedHandler}
              updateMeta={this.metaChangedHandler}
              updateParameter={this.parameterChangedHandler}
              disableButton={this.buttonStateChangedHandler}
              updateLabel={this.labelChangedHandler}
              updateButtonStyle={this.buttonStyleChangedHandler}
              updateFilename={this.filenameChangedHandler}
              updateCustomCssButton={this.buttonCSStateChangedHandler} />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Main;
