import React from 'react';
import { Button } from '@tableau/tableau-ui';
import Templates from '../../../utils/templates';
import SelectedIcon from '../../Configure/SelectIcons/SelectIcons';
import Grid from '@material-ui/core/Grid';

function ExportBtn(props) {
  return (
      <div>
        <style className="style-button">{
          props.style === "grutinet" ? Templates.defaultCSSButton() : 
            props.style === "personalizar" && props.cssStyle ? 
            props.cssStyle.replace(/(\r\n|\n|\r|"|\\n|\\)/gm, "") : ""
        }</style>
        <Button 
          kind={props.style} 
          className={props.style==="grutinet" || props.style === "personalizar" ? "btn-material": ""} 
          disabled={props.disabled} 
          onClick={props.export}>
            {
            props.icon ? 
              <Grid container>
                <SelectedIcon icon={props.icon}/><span style={{marginTop: 2, marginLeft: 2}}>{props.label}</span>
              </Grid> : ""
            }
            {props.label && !props.icon ? props.label : ""}
        </Button>
      </div>
  );
}

export default ExportBtn;
