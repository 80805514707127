import React from 'react';
import { Checkbox } from '@tableau/tableau-ui';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '50%',
  },
  label: {
    display: 'block',
    fontSize: 12,
    lineHeight: '21px',
    order: 0
  },
  group: {
    color: 'rgba(0, 0, 0, 0.56)',
    display: 'inline-flex',
    flexDirection: 'column',
    fontFamily: 'Benton Sans, Arial, Helvetica, sans-serif',
    position: 'relative',
  },
  strikeThrough: {
    textDecoration: 'line-through',
    marginRight: 5,
    color: 'rgba(156, 156, 156, 0.8)',
  }
}));



function Sheet(props) {
  // alert("aaaa "+props.value)
  const classes = useStyles();

  function renderSheetName(name) {return (<div>{name}</div>)}

  return (
    <div className={classes.root}>
      <Checkbox checked={props.selected} onChange={props.updateParameter}>{renderSheetName(props.param)}</Checkbox>
    </div>
  );

}

export default Sheet;
