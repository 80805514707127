import React from 'react';

function SelectIcons(props) {
    var error = true
    if(props.icon){
        try {
            var Icon = require(`@material-ui/icons/${props.icon}`).default
            error = false
        } catch (error) {
            error = false
        }
    }

  if (props && props.icon && !error) {
    return (<Icon/>);
  } else return (<div></div>)

}

export default SelectIcons;
