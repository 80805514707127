import React from 'react';
import Sheet from './Sheet/Sheet'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Folder from '@material-ui/icons/Folder';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  gridItem: {
    marginTop: 20
  }
}));

function SelectColumns(props) {
  const classes = useStyles();
  if (props && props.sheets) {
    return props.sheets.map((sheet, index) => {
      if (sheet.selected) {
        return (
          <div key={sheet.sheetName} style={{ marginTop: "15px" }}>
            <Grid container>
              <Grid item xs={6}>
                <Grid container>
                  <Folder />
                  <b style={{ color: "green", marginLeft: 10 }}>{sheet.sheetName}</b>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <label style={{ fontSize: 12, marginLeft: 35 }}>
                  {sheet.columns.filter(sheet => sheet.selected).length} campos seleccionados
                </label>
              </Grid>

            </Grid>
            <Sheet
              id={index}
              name={sheet.sheetName}
              cols={sheet.columns}
              colSelect={props.colSelect}
              changeName={props.changeName}
              changeOrder={props.changeOrder}
            />
          </div>
        );
      } else {
        return (
          <div className={classes.root} style={{ marginTop: "15px" }}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Folder />
                  <div key={sheet.sheetName} style={{ color: "red",marginLeft:10, textDecoration: "line-through" }}>
                    {sheet.sheetName}
                  </div></Grid>

              </Grid>
            </Grid>
          </div>
        )
      }
    });
  } else {
    return (
      <div></div>
    )
  }

}

export default SelectColumns;
