const Templates = {
    defaultCSSButton: () =>{
        return `             
        .btn-material { padding: 10px; border-radius: 2px; background-color: #ffffff; box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); color: #616160; line-height: none; font-family: Roboto, sans-serif; font-size: 13px; letter-spacing: 0.06em; text-transform: uppercase; text-decoration: none; }  .btn-material:hover, .btn-material:focus { box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); color: #ffffff; background-color: #e62c3d; }  .btn-material:active { box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); color: #ffffff; background-color: #f9c46b; }
        `
    },
    test: (a) =>{
        return `${a}`
    }
}

export default Templates;