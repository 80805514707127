import React, { useEffect, useState } from 'react';
import ExportBtn from './ExportBtn/ExportBtn';
import { initializeMeta, getParameters, revalidateMeta, saveSettings, setSettings, sendData, exportToSend, exportToFile } from '../func/func';
import compareVersions from 'compare-versions';
import { Spinner } from '@tableau/tableau-ui';

function Extension(props) {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    console.log('[Extension.js] Props Changed', props);
  }, [props]);

  useEffect(() => {
    console.log('[Extension.js] useEffect');
    //console.log('[Extension.js] Initialise Extension', props);
    //Initialise Extension
    if (window.tableau) {
      window.tableau.extensions.initializeAsync({ 'configure': configure }).then(() => {

        let metaVersion = window.tableau.extensions.settings.get('metaVersion');

        if (metaVersion) {
          console.log('[Extension.js] Meta Version', metaVersion);
          props.updateMetaVersion(metaVersion);
        } else {
          console.log('[Extension.js] Meta Version', 1);
          metaVersion = 1;
          props.updateMetaVersion(1);
        }

        let sheetSettings = window.tableau.extensions.settings.get('selectedSheets');

        if (sheetSettings && sheetSettings != null) {
          console.log('[Extension.js] Existing Sheet Settings Found', JSON.parse(sheetSettings));
          props.updateMeta(JSON.parse(sheetSettings));
          props.disableButton(false);
        } else {
          console.log('[Extension.js] Can\'t find existing sheet settings');
          initializeMeta()
            .then(meta => {
              props.updateMeta(meta);
              setSettings('sheets', meta);
              setSettings('label', 'Export All');
              return saveSettings();
            })
            .then(meta => configure(meta));


        }

        let labelSettings = window.tableau.extensions.settings.get('buttonLabel');

        if (labelSettings && labelSettings != null) {
          labelSettings = labelSettings.replace(/"/g, '');
          console.log('[Extension.js] initializeAsync Existing Label Settings Found', labelSettings);
          props.updateLabel(labelSettings);
        }

        const ignoreSelectionSettings = window.tableau.extensions.settings.get('ignoreSelection');
        if (ignoreSelectionSettings && ignoreSelectionSettings != null) {
          console.log('[Extension.js] initializeDialogAsync Existing updateIgnoreSelection Settings Found', ignoreSelectionSettings);
          props.updateIgnoreSelection(ignoreSelectionSettings);
        }

        let styleSettings = window.tableau.extensions.settings.get('buttonStyle');

        if (styleSettings && styleSettings != null) {
          console.log('[Extension.js] initializeAsync Existing Label Style Found', styleSettings);
          props.updateButtonStyle(styleSettings);
        }

        let customStyleSettings = window.tableau.extensions.settings.get('cssStyle');
        // alert("customStyleSettings ---"+customStyleSettings)
        if (customStyleSettings && customStyleSettings != null) {
          // console.log('[Extension.js] refreshSettings Existing Label Style Found', styleSettings);
          props.updateCustomCssButton(customStyleSettings);
        }

        let filenameSettings = window.tableau.extensions.settings.get('filename');

        if (filenameSettings && filenameSettings != null) {
          console.log('[Extension.js] initializeAsync Existing Filename Found', filenameSettings);
          props.updateFilename(filenameSettings);
        }

        let iconSettings = window.tableau.extensions.settings.get('icon');

        if (iconSettings && iconSettings != null) {
          props.updateIcon(JSON.parse(iconSettings));
        }

      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refreshSettings() {
    let sheetSettings = window.tableau.extensions.settings.get('selectedSheets');

    if (sheetSettings && sheetSettings != null) {
      const existingSettings = JSON.parse(sheetSettings);
      console.log('[Extension.js] refreshSettings Existing Sheet Settings Found. Refreshing', JSON.stringify(existingSettings));
      revalidateMeta(existingSettings)
        .then(meta => {
          console.log('[Extension.js] refreshSettings settings revalidated', JSON.stringify(meta));
          props.updateMeta(meta);
          props.disableButton(false);
        });
    }

    let labelSettings = window.tableau.extensions.settings.get('buttonLabel');

    if (labelSettings && labelSettings != null) {
      labelSettings = labelSettings.replace(/"/g, '');
      console.log('[Extension.js] refreshSettings Existing Label Settings Found', labelSettings);
      props.updateLabel(labelSettings);
    }

    let styleSettings = window.tableau.extensions.settings.get('buttonStyle');

    if (styleSettings && styleSettings != null) {
      console.log('[Extension.js] refreshSettings Existing Label Style Found', styleSettings);
      props.updateButtonStyle(styleSettings);
    }

    const ignoreSelectionSettings = window.tableau.extensions.settings.get('ignoreSelection');
      if (ignoreSelectionSettings && ignoreSelectionSettings != null) {
        console.log('[Extension.js] initializeDialogAsync Existing ignoreSelectionSettings Settings Found', ignoreSelectionSettings);
        props.updateIgnoreSelection(ignoreSelectionSettings);
      }

    let customStyleSettings = window.tableau.extensions.settings.get('cssStyle');
    // alert("customStyleSettings ---"+customStyleSettings)
    if (customStyleSettings && customStyleSettings != null) {
      // console.log('[Extension.js] refreshSettings Existing Label Style Found', styleSettings);
      props.updateCustomCssButton(customStyleSettings);
    }

    let iconSettings = window.tableau.extensions.settings.get('icon');

    if (iconSettings && iconSettings != null) {
      props.updateIcon(JSON.parse(iconSettings));
    }

    let filenameSettings = window.tableau.extensions.settings.get('filename');

    if (filenameSettings && filenameSettings != null) {
      console.log('[Extension.js] refreshSettings Existing Filename Found', filenameSettings);
      props.updateFilename(filenameSettings);
    }
  }

  function configure() {
    console.log('[Extension.js] Opening configure popup');
    const popupUrl = `${window.location.origin}/configure/a`;
    window.tableau.extensions.ui.displayDialogAsync(popupUrl, null, { height: 500, width: 500 }).then((closePayload) => {
      refreshSettings();
      console.log('[Extension.js] Config window closed', props)
    }).catch((error) => {
      switch (error.errorCode) {
        case window.tableau.ErrorCodes.DialogClosedByUser:
          console.log('[Extension.js] Dialog was closed by user');
          refreshSettings();
          break;
        default:
          console.error('[Extension.js]', error.message);
      }
    });
  }

  async function clickExportHandler() {
    setLoading(true)
    let sheetSettings = window.tableau.extensions.settings.get('selectedSheets');
    let formatSettings = window.tableau.extensions.settings.get('format');
    let urlSettings = window.tableau.extensions.settings.get('url');
    let parameterSettings = window.tableau.extensions.settings.get('parameters');

    const meta = JSON.parse(sheetSettings);
    // alert(JSON.stringify(meta))
    const format = JSON.parse(formatSettings)
    const url = JSON.parse(urlSettings)
    const parameters = JSON.parse(parameterSettings)

    if (format === "excel" || format === "csv") {
      if (window.tableau.extensions.environment.context === "server") {
        await exportToFile(meta, 'server', props.filename, format, parameters, props.ignoreSelection );
      } else {
        console.log('[Extension.js] Tableau Version', window.tableau.extensions.environment.tableauVersion);
        if (compareVersions.compare(window.tableau.extensions.environment.tableauVersion, '2019.4.0', '>=')) {
          await exportToFile(meta, 'desktop', props.filename, format, parameters, props.ignoreSelection);
        } else {
          desktopExportHandler();
        }
      }
    } else if (format === "url") {
      // alert("ENVIAMOS POR URL a " + JSON.stringify(parameters))
      // https://node-red-dev.aplisein.com/sendData
      if (url) {
        var loadingResponse = true
        let responseData = await exportToSend(meta, format, parameters, props.ignoreSelection);
        setTimeout(() => {
          if (loadingResponse) {
            setLoading(false)
            alert("La petición ha superado el tiempo de demora!")
          }
        }, 15000)
        let response = await sendData(url, responseData)
        if (response && response.ok) {
          loadingResponse = false
          alert(await response.text())
        }

        setLoading(false)
      } else alert("Cuidado no tienes una url especificada!")
    } else alert("formato de extracción erroneo!")

    setLoading(false)
  }

  function desktopExportHandler() {
    const popupUrl = `${window.location.origin}/desktopexport`;
    window.tableau.extensions.ui.displayDialogAsync(popupUrl, '', { height: 350, width: 400 }).then((closePayload) => {
      console.log('[Extension.js] Export window closed')
    }).catch((error) => {
      switch (error.errorCode) {
        case window.tableau.ErrorCodes.DialogClosedByUser:
          console.log('[Extension.js] Export window was closed by user');
          break;
        default:
          console.error('[Extension.js]', error.message);
      }
    });
  }

  return (
    <>
      {
        loading ? <Spinner /> :
          <ExportBtn
            icon={props.icon}
            cssStyle={props.cssStyle}
            label={props.label}
            style={props.style}
            disabled={props.disabled}
            export={clickExportHandler}>
          </ExportBtn>
      }
    </>
  );
}

export default Extension;
